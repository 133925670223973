<template>
  <page-manage-base>
    <template v-slot:title class="mx-12 my-4 booking-list-title"
      >{{ $route.name }}
    </template>
    <template v-slot:default>
      <v-card class="mx-10 mb-16 btn-page-right" elevation="0">
        <div>
          <v-btn
            v-if="checkPerUser"
            :disabled="!checkPerUser"
            @click="showDialogUploadCV()"
            outlined
            download
            color="#3DABDC"
            class="my-5"
          >
            <p class="btn-custom-text">
              {{ $t('webPageManage.oldCustomer.btnCsv') }}
            </p>
          </v-btn>
        </div>
        <!-- Table -->
        <Table
          :attr="{
            'server-items-length': total,
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id'
          }"
          ref="table"
          :itemsPerPage="30"
          :itemsPerPageOptions="[30, 60, 120, 480, 1000]"
          :headers="headers"
          :total="total"
          :items="webRequestlistDocumentRequestlistFalse"
          :funReset="getWebRequestlistDocumentRequestlists"
          :filter="filter"
          :sortField="[]"
          :multiSort="true"
        >
          <template v-slot:[`item.mediasType`]="{ item }">
            <span>
              {{ item.medias ? showMediasType(item.medias) : null }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt.substr(5, 2) }}/{{ item.createdAt.substr(8, 2) }}
          </template>
          <template v-slot:[`item.isCompany`]="{ item }">
            <div v-if="item.client">
              {{ item.client.isCompany ? '法人' : '個人' }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.client">
              {{ item.client.name }}
            </div>
          </template>
          <template v-slot:[`item.forVmAndforVs`]="{ item }">
            <span v-if="item.forVm">VM,&nbsp;</span>
            <span v-else></span>
            <span v-if="item.forVs">VS</span>
          </template>
          <template v-slot:[`item.picName`]="{ item }">
            {{ item.campaign ? item.campaign.name.toUpperCase() : '' }}
          </template>
          <template v-slot:[`item.importMethod`]="{ item }">
            {{ item.importMethod.toUpperCase() }}
          </template>

          <template v-slot:[`item.user`]="{ item }">
            <v-btn class="t-btn--prm" icon @click="redirectCustomeDetail(item)">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              @click="handleEditDialog(item.client.isCompany, item)"
              icon
            >
              <v-icon x-large>mdi-pencil-circle</v-icon>
            </v-btn>
          </template>
        </Table>
      </v-card>
      <!-- Dialog -->
      <CSVUpload
        v-if="checkPerUser"
        :key="indexCSVUploadCustomer"
        :visible="visible === 3"
        @close="visible = -1"
      />
      <documentRequestDetailCorporation
        v-if="checkPerUser"
        :dataUrl="data"
        :visible="visible === 0"
        @close="visible = -1"
        @reloadList="reloadList"
      ></documentRequestDetailCorporation>
      <documentRequestDetailIndividual
        v-if="checkPerUser"
        :visible="dialogDetail"
        :dataUrl="data"
        @close="dialogDetail = false"
        @reloadList="reloadList"
        hidePic
      ></documentRequestDetailIndividual>
    </template>
  </page-manage-base>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import PageManageBase from '../PageManageBase.vue';
import Table from '@/components/Table/index.vue';
import documentRequestDetailIndividual from '@/views/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/documentRequestDetailIndividual.vue';
import CSVUpload from '../common/CSVUpload.vue';
import { mapActions, mapMutations } from 'vuex';
import DocumentRequestDetailCorporation from '../documentrequest/DuplicateExistingCustomers/documentRequestDetailCorporation.vue';
import { WEB_REQUESTLIST_DOCUMENT_REQUESTLIST } from '@/api/graphql/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/index.js';
import gql from 'graphql-tag';

export default {
  name: 'BookingList',
  data() {
    return {
      total: null,
      webRequestlistDocumentRequestlistFalse: null,
      filter: {
        hasPic: null,
        forVs: true,
      },
      data: {},
      indexCSVUploadCustomer: 0,
      currentId: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      dialogDetail: false,
      headers: [
        {
          text: '受信日',
          value: 'createdAt',
          fieldName: 'webRequest.createdAt',
        },
        {
          text: '反響媒体',
          fieldName: 'media.mediaType',
          value: 'mediasType',
        },
        {
          text: this.$t('webPageManage.documentRequest.CustomerDetails'),
          sortable: false,
          value: 'user',
          algin: 'center',
        },
        {
          text: '法人/個人',
          fieldName: 'client.isCompany',
          value: 'isCompany',
        },
        {
          text: '名前/会社名',
          sortable: false,
          value: 'name',
        },
        {
          text: '請求資料',
          fieldName: 'webRequest.forVm',
          value: 'forVmAndforVs',
        },
        {
          text: '広告施策',
          fieldName: 'campaign.name',
          value: 'picName',
        },
        {
          text: '取り込み方法',
          fieldName: 'webRequest.importMethod',
          value: 'importMethod',
        },
        {
          text: this.$t('webPageManage.documentRequest.detail'),
          sortable: false,
          value: 'edit',
          algin: 'center',
        },
      ],
      items: ['今津 康平', '池田 祥子', '田中 智弘', ' 藤井 真衣', '西山 早紀'],
    };
  },
  methods: {
    ...mapActions([
      'getWebRequestlistOldCustomerlist',
      'getWebrequestNotIscopanyDocumentRequestList',
    ]),
    ...mapMutations(['setWebRequestlistDocumentRequestListNull']),
    reloadList(value, id) {
      const newArr = this.webRequestlistDocumentRequestlistNull;
      const objIndex = newArr.findIndex((e) => e.id === id);
      newArr[objIndex].client.pic.id = value.client.pic.id;
      newArr[objIndex].client.pic.name = value.client.pic.name;
      this.setWebRequestlistDocumentRequestListNull(newArr);
    },

    async getWebRequestlistDocumentRequestlists(variables) {
      await this.$apollo
        .query({
          query: gql`
            ${WEB_REQUESTLIST_DOCUMENT_REQUESTLIST}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then((data) => {
          this.webRequestlistDocumentRequestlistFalse =
            data.data.webRequestList.items;
          this.total = data.data.webRequestList.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async handleEditDialog(isCompany, item) {
      await this.getWebrequestNotIscopanyDocumentRequestList(item.id).then(
        async (result) => {
          this.currentId = item.id;
          if (isCompany) {
            this.visible = 0;
          } else {
            this.dialogDetail = true;
          }
        },
      );
    },
    
    showMediasType(el) {
      if (el.length > 0) {
        let mess = '';
        el.forEach((element, idx) => {
          mess += element.mediaType;
          if (idx < el.length - 1) {
            mess += ', ';
          }
        });
        return mess.length > 20 ? mess.slice(0, 20) + '...' : mess;
      } else {
        return '';
      }
    },

    redirectCustomeDetail(item) {
      let url = '';
      if (item.client.type === 'VSINTEREST') {
        url = '/customer-details/prospect-information';
      } else {
        url = '/customer-details';
      }
      const routeData = `${url}/${item.client.id}?typeClient=${item.client.type}`;
      window.open(routeData, '_blank');
      // const routeData = this.$router.resolve({
      //     path: '/customer-details/' +
      //                 item.client.id + '?&typeClient=' + item.client.type
      //   })
      // window.open(routeData.href, '_blank')
    },

    showDialogUploadCV() {
      this.indexCSVUploadCustomer = this.indexCSVUploadCustomer + 1;
      this.visible = 3;
    },
  },
  components: {
    PageManageBase,
    CSVUpload,
    documentRequestDetailIndividual,
    DocumentRequestDetailCorporation,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.btn-custom-text {
  margin: 0;
  color: #13ace0;
  font-size: 12px;
}
.pencil {
  background: var(--ffffff) 0% 0% no-repeat padding-box !important;
  background: #13ace0 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1;
  height: 30px;
  width: 30px;
}
.contact {
  color: #13ace0 !important;
}
.icon-btn {
  border-radius: 50%;
  font-size: 23px !important;
  color: #fff !important;
}
.v-data-table::v-deep {
  tr {
    height: auto !important;
  }
  th {
    color: #757575 !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  td {
    color: #757575 !important;
    font-size: 14px !important;
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
      margin: 2px;
    }
  }
  .v-data-footer {
    position: relative;
    font-size: 11px;

    & > div:nth-child(2) {
      margin: 0;
    }
  }
}
::v-deep {
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-manage-base',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.name)+" ")]},proxy:true},{key:"default",fn:function(){return [_c('v-card',{staticClass:"mx-10 mb-16 btn-page-right",attrs:{"elevation":"0"}},[_c('div',[(_vm.checkPerUser)?_c('v-btn',{staticClass:"my-5",attrs:{"disabled":!_vm.checkPerUser,"outlined":"","download":"","color":"#3DABDC"},on:{"click":function($event){return _vm.showDialogUploadCV()}}},[_c('p',{staticClass:"btn-custom-text"},[_vm._v(" "+_vm._s(_vm.$t('webPageManage.oldCustomer.btnCsv'))+" ")])]):_vm._e()],1),_c('Table',{ref:"table",attrs:{"attr":{
          'server-items-length': _vm.total,
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id'
        },"itemsPerPage":30,"itemsPerPageOptions":[30, 60, 120, 480, 1000],"headers":_vm.headers,"total":_vm.total,"items":_vm.webRequestlistDocumentRequestlistFalse,"funReset":_vm.getWebRequestlistDocumentRequestlists,"filter":_vm.filter,"sortField":[],"multiSort":true},scopedSlots:_vm._u([{key:"item.mediasType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.medias ? _vm.showMediasType(item.medias) : null)+" ")])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.substr(5, 2))+"/"+_vm._s(item.createdAt.substr(8, 2))+" ")]}},{key:"item.isCompany",fn:function(ref){
        var item = ref.item;
return [(item.client)?_c('div',[_vm._v(" "+_vm._s(item.client.isCompany ? '法人' : '個人')+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.client)?_c('div',[_vm._v(" "+_vm._s(item.client.name)+" ")]):_vm._e()]}},{key:"item.forVmAndforVs",fn:function(ref){
        var item = ref.item;
return [(item.forVm)?_c('span',[_vm._v("VM, ")]):_c('span'),(item.forVs)?_c('span',[_vm._v("VS")]):_vm._e()]}},{key:"item.picName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.campaign ? item.campaign.name.toUpperCase() : '')+" ")]}},{key:"item.importMethod",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.importMethod.toUpperCase())+" ")]}},{key:"item.user",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":""},on:{"click":function($event){return _vm.redirectCustomeDetail(item)}}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"btn-crm-primary",staticStyle:{"border":"0px"},attrs:{"disabled":!_vm.checkPerUser,"icon":""},on:{"click":function($event){return _vm.handleEditDialog(item.client.isCompany, item)}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-pencil-circle")])],1)]}}],null,true)})],1),(_vm.checkPerUser)?_c('CSVUpload',{key:_vm.indexCSVUploadCustomer,attrs:{"visible":_vm.visible === 3},on:{"close":function($event){_vm.visible = -1}}}):_vm._e(),(_vm.checkPerUser)?_c('documentRequestDetailCorporation',{attrs:{"dataUrl":_vm.data,"visible":_vm.visible === 0},on:{"close":function($event){_vm.visible = -1},"reloadList":_vm.reloadList}}):_vm._e(),(_vm.checkPerUser)?_c('documentRequestDetailIndividual',{attrs:{"visible":_vm.dialogDetail,"dataUrl":_vm.data,"hidePic":""},on:{"close":function($event){_vm.dialogDetail = false},"reloadList":_vm.reloadList}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }